import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/rider`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/rider/${data}`)
  return response.data
}

const exportRidersToExcell = async (data) => {
  const response = await AuthAPI.get(`/admin/export-riders-to-excell`)
  // return response.data
}

const createRider = async (data) => {
  const response = await AuthAPI.post(`/admin/rider`, data)
  return response.data
}

const editRider = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/update`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/rider/delete/${data}`)
  return response.data
}

const assignOrderToRider = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/assign`, data)
  return response.data
}

const blockOrUnblockRider = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/block/${data}`)
  return response.data
}

const approveRiderDocuments = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/rider-doc/approve-all/${data}`)
  return response.data
}

const declineRiderDocuments = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/rider-doc/decline`, data)
  return response.data
}

const dismissOrUnDismissRider = async (data) => {
  const response = await AuthAPI.post(`/admin/rider/dismiss/${data}`)
  return response.data
}

const chatRider = async (data) => {
  const response = await AuthAPI.post(`/user/chat/send-message`, data)
  return response.data
}

const getRiderContactsInTheAdminApp = async (data) => {
  const response = await AuthAPI.get(`/admin/rider/chat/list-contacts/${data}`)
  return response.data
}

const getChatsBetweenRiders = async (riderId, userId) => {
  const response = await AuthAPI.get(`/admin/rider/chat/fetch-messages/${riderId}/${userId}`)
  return response.data
}

export const RiderService = {
  getAll,
  getOne,
  createRider,
  editRider,
  deleteOne,
  assignOrderToRider,
  blockOrUnblockRider,
  dismissOrUnDismissRider,
  approveRiderDocuments,
  declineRiderDocuments,
  chatRider,
  getRiderContactsInTheAdminApp,
  getChatsBetweenRiders,
  exportRidersToExcell,
}
