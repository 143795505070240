import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RiderService } from '../services/RiderService'

export const getAllRiders = createAsyncThunk('rider/getAll', async (_, { rejectWithValue }) => {
  try {
    const response = await RiderService.getAll()
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getOneRider = createAsyncThunk('rider/getOne', async (data, { rejectWithValue }) => {
  try {
    const response = await RiderService.getOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createRider = createAsyncThunk('rider/create', async (data, { rejectWithValue }) => {
  try {
    const response = await RiderService.createRider(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const editRider = createAsyncThunk('rider/edit', async (data, { rejectWithValue }) => {
  try {
    const response = await RiderService.editRider(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteRider = createAsyncThunk('rider/delete', async (data, { rejectWithValue }) => {
  try {
    const response = await RiderService.deleteOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const assignOrderToRider = createAsyncThunk(
  'rider/assignorder',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.assignOrderToRider(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const dismissOrUnDismissRider = createAsyncThunk(
  'rider/dismiss',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.dismissOrUnDismissRider(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const blockOrUnblockRider = createAsyncThunk(
  'rider/block',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.blockOrUnblockRider(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const approveRiderDocuments = createAsyncThunk(
  'rider/approveDocs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.approveRiderDocuments(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const declineRiderDocuments = createAsyncThunk(
  'rider/declineDocs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.declineRiderDocuments(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const chatRider = createAsyncThunk(
  'rider/sendmessage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.chatRider(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getRiderContactsInTheAdminApp = createAsyncThunk(
  'rider/getchatContacts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RiderService.getRiderContactsInTheAdminApp(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'rider',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllRiders.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllRiders.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllRiders.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getRiderContactsInTheAdminApp.pending]: (state) => {
      state.loading = true
    },
    [getRiderContactsInTheAdminApp.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getRiderContactsInTheAdminApp.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [getOneRider.pending]: (state) => {
      state.loading = true
    },
    [getOneRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createRider.pending]: (state) => {
      state.loading = true
    },
    [createRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [chatRider.pending]: (state) => {
      state.loading = true
    },
    [chatRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [chatRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editRider.pending]: (state) => {
      state.loading = true
    },
    [editRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteRider.pending]: (state) => {
      state.loading = true
    },
    [deleteRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [assignOrderToRider.pending]: (state) => {
      state.loading = true
    },
    [assignOrderToRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [assignOrderToRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [blockOrUnblockRider.pending]: (state) => {
      state.loading = true
    },
    [blockOrUnblockRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [blockOrUnblockRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [approveRiderDocuments.pending]: (state) => {
      state.loading = true
    },
    [approveRiderDocuments.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [approveRiderDocuments.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [declineRiderDocuments.pending]: (state) => {
      state.loading = true
    },
    [declineRiderDocuments.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [declineRiderDocuments.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [dismissOrUnDismissRider.pending]: (state) => {
      state.loading = true
    },
    [dismissOrUnDismissRider.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [dismissOrUnDismissRider.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
