import { combineReducers } from '@reduxjs/toolkit'
import auth from '../authSlice'
import app from '../appSlice'
import bookings from '../bookingSlice'
import clients from '../clientSlice'
import serviceCategory from '../serviceCategorySlice'
import serviceProviders from '../serviceProviderSlice'
import paymentMethods from '../paymentMethodSlice'
import transactions from '../transactionSlice'
import profile from '../profileSlice'
import subjects from '../subjectSlice'
import learnerClasses from '../LearnerClassSlice'
import learnerAges from '../LearnerAgeSlice'
import courses from '../courseSlice'
import categories from '../categorySlice'
import companies from '../companySlice'
import insurances from '../InsuranceSlice'
import lessons from '../lessonSlice'
import claims from '../claimSlice'
import users from '../userSlice'
import orders from '../orderSlice'
import payments from '../paymentSlice'
import contacts from '../supportSlice'
import notifications from '../notificationSlice'
import riders from '../riderSlice'
import pickupCharges from '../pickupChargeSlice'
import hubLocations from '../hubLocationSlice'
import pricingParameters from '../pricingParameterSlice'
import admins from '../AdminSlice'
import general_settings from '../generalSettingSlice'
import chats from '../chatSlice'
import activeMessages from '../usersMessagesSlice'
import products from '../productSlice'
import locations from '../locationSlice'

const rootReducer = combineReducers({
  auth,
  app,
  bookings,
  clients,
  serviceCategory,
  serviceProviders,
  paymentMethods,
  transactions,
  profile,
  subjects,
  learnerClasses,
  learnerAges,
  courses,
  categories,
  companies,
  insurances,
  lessons,
  claims,
  users,
  orders,
  payments,
  contacts,
  notifications,
  riders,
  pickupCharges,
  hubLocations,
  pricingParameters,
  admins,
  general_settings,
  chats,
  activeMessages,
  products,
  locations,
})

export default rootReducer
