import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { pricingParametersService } from '../services/pricingParametersService'

export const getAllPricingParameters = createAsyncThunk(
  'pricingParameter/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await pricingParametersService.getAll()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getOnePricingParameter = createAsyncThunk(
  'pricingParameter/getOne',
  async (data, { rejectWithValue }) => {
    try {
      const response = await pricingParametersService.getOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const createPricingParameter = createAsyncThunk(
  'pricingParameter/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await pricingParametersService.createPricingParameter(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editPricingParameter = createAsyncThunk(
  'pricingParameter/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await pricingParametersService.editPricingParameter(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deletePricingParameter = createAsyncThunk(
  'pricingParameter/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await pricingParametersService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'pricingParameter',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllPricingParameters.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllPricingParameters.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllPricingParameters.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOnePricingParameter.pending]: (state) => {
      state.loading = true
    },
    [getOnePricingParameter.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOnePricingParameter.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createPricingParameter.pending]: (state) => {
      state.loading = true
    },
    [createPricingParameter.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createPricingParameter.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editPricingParameter.pending]: (state) => {
      state.loading = true
    },
    [editPricingParameter.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editPricingParameter.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deletePricingParameter.pending]: (state) => {
      state.loading = true
    },
    [deletePricingParameter.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deletePricingParameter.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
