import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PickupChargeService } from '../services/PickupChargeService'

export const getAllPickupCharges = createAsyncThunk(
  'pickup/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PickupChargeService.getAll()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getOnePickupCharge = createAsyncThunk(
  'pickup/getOne',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PickupChargeService.getOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const createPickupCharge = createAsyncThunk(
  'pickup/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PickupChargeService.createPickupCharge(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editPickupCharge = createAsyncThunk(
  'pickup/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PickupChargeService.editPickupCharge(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deletePickupCharge = createAsyncThunk(
  'pickup/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PickupChargeService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'pickup',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllPickupCharges.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllPickupCharges.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllPickupCharges.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOnePickupCharge.pending]: (state) => {
      state.loading = true
    },
    [getOnePickupCharge.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOnePickupCharge.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createPickupCharge.pending]: (state) => {
      state.loading = true
    },
    [createPickupCharge.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createPickupCharge.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editPickupCharge.pending]: (state) => {
      state.loading = true
    },
    [editPickupCharge.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editPickupCharge.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deletePickupCharge.pending]: (state) => {
      state.loading = true
    },
    [deletePickupCharge.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deletePickupCharge.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
