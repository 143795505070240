import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GeneralSettingsService } from '../services/GeneralSettingsService'

export const getAllGeneralSettings = createAsyncThunk(
  'generalSetting/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.getAll()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getOneGeneralSetting = createAsyncThunk(
  'generalSetting/getOne',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.getOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const showgeneralSettingViaOption = createAsyncThunk(
  'generalSetting/getOneOption',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.showgeneralSettingViaOption(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const createGeneralSetting = createAsyncThunk(
  'generalSetting/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.createGeneralSetting(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editGeneralSetting = createAsyncThunk(
  'generalSetting/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.editGeneralSetting(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteGeneralSetting = createAsyncThunk(
  'generalSetting/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GeneralSettingsService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'generalSetting',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllGeneralSettings.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllGeneralSettings.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllGeneralSettings.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOneGeneralSetting.pending]: (state) => {
      state.loading = true
    },
    [getOneGeneralSetting.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneGeneralSetting.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [showgeneralSettingViaOption.pending]: (state) => {
      state.loading = true
    },
    [showgeneralSettingViaOption.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [showgeneralSettingViaOption.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createGeneralSetting.pending]: (state) => {
      state.loading = true
    },
    [createGeneralSetting.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createGeneralSetting.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editGeneralSetting.pending]: (state) => {
      state.loading = true
    },
    [editGeneralSetting.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editGeneralSetting.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteGeneralSetting.pending]: (state) => {
      state.loading = true
    },
    [deleteGeneralSetting.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteGeneralSetting.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
