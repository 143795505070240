import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/general-setting/list`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/general-setting/show/${data}`)
  return response.data
}

const showgeneralSettingViaOption = async (data) => {
  const response = await AuthAPI.get(`/admin/general-setting/option/${data}`)
  return response.data
}

const createGeneralSetting = async (data) => {
  const response = await AuthAPI.post(`/admin/general-setting/add-option`, data)
  return response.data
}

const editGeneralSetting = async (data) => {
  const response = await AuthAPI.post(`/admin/general-setting/update-option`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/general-setting/delete/${data}`)
  return response.data
}

export const GeneralSettingsService = {
  getAll,
  getOne,
  createGeneralSetting,
  editGeneralSetting,
  deleteOne,
  showgeneralSettingViaOption,
}
