import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LocationService } from '../services/LocationService'

export const getLGAsByState = createAsyncThunk(
  'location/getlgas',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LocationService.getLGAsByStateId()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getCitiesByLGA = createAsyncThunk(
  'location/getcitiesByLGA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LocationService.getCitiesByLGAId(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getLGADetail = createAsyncThunk(
  'location/getOneLga',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LocationService.getLGA(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const importLGAandCities = createAsyncThunk(
  'location/import-location',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LocationService.importLGAandCitiesExcelFile(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteLGA = createAsyncThunk(
  'location/delete-lga',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LocationService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getLGAsByState.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getLGAsByState.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getLGAsByState.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getLGADetail.pending]: (state) => {
      state.loading = true
    },
    [getLGADetail.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getLGADetail.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [importLGAandCities.pending]: (state) => {
      state.loading = true
    },
    [importLGAandCities.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [importLGAandCities.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [getCitiesByLGA.pending]: (state) => {
      state.loading = true
    },
    [getCitiesByLGA.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [getCitiesByLGA.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteLGA.pending]: (state) => {
      state.loading = true
    },
    [deleteLGA.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteLGA.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
