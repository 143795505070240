import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { notificationService } from '../services/notificationService'

export const getAllNotifications = createAsyncThunk(
  'notification/getAll',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.getAll(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)
export const getEmailAndPushNotifications = createAsyncThunk(
  'notification/getAllPushAndEmail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.getEmailAndPushNotifications(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)
export const getOneEmailAndPushNotification = createAsyncThunk(
  'notification/getOnePushAndEmail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.getOneEmailAndPushNotification(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const getOneNotification = createAsyncThunk(
  'notification/getOne',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.getOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const createNotification = createAsyncThunk(
  'notification/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.createNotification(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const sendPushNotificationToUsers = createAsyncThunk(
  'notification/sendpushNoty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.sendPushNotificationToUsers(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const editNotification = createAsyncThunk(
  'notification/edit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.editNotification(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.deleteOne(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  notificationData: [],
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllNotifications.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllNotifications.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllNotifications.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getEmailAndPushNotifications.pending]: (state) => {
      if (state.notificationData.length <= 0) {
        state.loading = true
      }
    },
    [getEmailAndPushNotifications.fulfilled]: (state, action) => {
      state.error = false
      state.notificationData = action.payload
      state.loading = false
    },
    [getEmailAndPushNotifications.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOneNotification.pending]: (state) => {
      state.loading = true
    },
    [getOneNotification.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneNotification.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [getOneEmailAndPushNotification.pending]: (state) => {
      state.loading = true
    },
    [getOneEmailAndPushNotification.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneEmailAndPushNotification.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createNotification.pending]: (state) => {
      state.loading = true
    },
    [createNotification.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createNotification.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [sendPushNotificationToUsers.pending]: (state) => {
      state.loading = true
    },
    [sendPushNotificationToUsers.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [sendPushNotificationToUsers.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editNotification.pending]: (state) => {
      state.loading = true
    },
    [editNotification.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editNotification.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteNotification.pending]: (state) => {
      state.loading = true
    },
    [deleteNotification.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteNotification.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
