import AuthAPI from './authInstance'

const getAll = async () => {
  const response = await AuthAPI.get(`/admin/mile-12-market/order/list`)
  return response.data
}

const getOrdersByType = async (type) => {
  const response = await AuthAPI.get(`/admin/order/list/${type}`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/mile-12-market/order/show/${data}`)
  return response.data
}

const searchOrderByTrackno = async (data) => {
  const response = await AuthAPI.get(`/admin/mile-12-market/order/${data}`)
  return response.data
}
const updateOrderStatus = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/order/update-status`, data)
  return response.data
}
export const orderService = {
  getAll,
  getOne,
  searchOrderByTrackno,
  updateOrderStatus,
  getOrdersByType,
}
