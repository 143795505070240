import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/admin/mile-12-market/product/my-product/get-all`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/mile-12-market/product/my-product/get-one/${data}`)
  return response.data
}

const createProduct = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/product/create`, data)
  return response.data
}

const editProduct = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/product/update`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/mile-12-market/product/delete/${data}`)
  return response.data
}

const deleteProductImage = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/product/delete-image`, data)
  return response.data
}

const changeProductStatus = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/product/update-status`, data)
  return response.data
}

export const productService = {
  getAll,
  getOne,
  createProduct,
  editProduct,
  deleteOne,
  deleteProductImage,
  changeProductStatus,
}
