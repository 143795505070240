import AuthAPI from './authInstance'

const getUserContacts = async (data) => {
  const response = await AuthAPI.get(`/user/chat/list-contacts`)
  return response.data
}

const fetchUserMessages = async (data) => {
  const response = await AuthAPI.get(`/user/chat/fetch-messages/${data}`)
  return response.data
}

const sendMessage = async (data) => {
  const response = await AuthAPI.post(`/user/chat/send-message`, data)
  return response.data
}

const searchMessage = async (data) => {
  const response = await AuthAPI.post(`/user/chat/search-chat`, data)
  return response.data
}

const searchUsersToChatWith = async (data) => {
  const response = await AuthAPI.post(`/admin/user/search-all-users`, data)
  return response.data
}

const deleteMessage = async (data) => {
  const response = await AuthAPI.delete(`/user/chat/delete-chat/${data}`)
  return response.data
}

export const chatService = {
  getUserContacts,
  fetchUserMessages,
  sendMessage,
  searchMessage,
  searchUsersToChatWith,
  deleteMessage,
}
