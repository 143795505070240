import AuthAPI from './authInstance'

const getAll = async (data) => {
  const response = await AuthAPI.get(`/user/hub-location/list`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/user/hub-location/show/${data}`)
  return response.data
}

const createHubLocation = async (data) => {
  const response = await AuthAPI.post(`/admin/hub-location/create`, data)
  return response.data
}

const editHubLocation = async (data) => {
  const response = await AuthAPI.post(`/admin/hub-location/update`, data)
  return response.data
}

const deleteOne = async (data) => {
  const response = await AuthAPI.delete(`/admin/hub-location/delete/${data}`)
  return response.data
}

export const HubLocationService = {
  getAll,
  getOne,
  createHubLocation,
  editHubLocation,
  deleteOne,
}
