import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AdminService } from '../services/AdminService'

export const getAllAdmins = createAsyncThunk('admin/getAll', async (_, { rejectWithValue }) => {
  try {
    const response = await AdminService.getAll()
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getOneAdmin = createAsyncThunk('rider/getOne', async (data, { rejectWithValue }) => {
  try {
    const response = await AdminService.getOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const createAdmin = createAsyncThunk('admin/create', async (data, { rejectWithValue }) => {
  try {
    const response = await AdminService.createAdmin(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const editAdmin = createAsyncThunk('admin/edit', async (data, { rejectWithValue }) => {
  try {
    const response = await AdminService.editAdmin(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteAdmin = createAsyncThunk('admin/delete', async (data, { rejectWithValue }) => {
  try {
    const response = await AdminService.deleteOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const assignCustomerToAccountOffer = createAsyncThunk(
  'admin/assigncustomer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdminService.assignCustomerToAccountOffer(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getAllAdmins.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getAllAdmins.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getAllAdmins.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [getOneAdmin.pending]: (state) => {
      state.loading = true
    },
    [getOneAdmin.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [getOneAdmin.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [createAdmin.pending]: (state) => {
      state.loading = true
    },
    [createAdmin.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [createAdmin.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [editAdmin.pending]: (state) => {
      state.loading = true
    },
    [editAdmin.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [editAdmin.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteAdmin.pending]: (state) => {
      state.loading = true
    },
    [deleteAdmin.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteAdmin.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [assignCustomerToAccountOffer.pending]: (state) => {
      state.loading = true
    },
    [assignCustomerToAccountOffer.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [assignCustomerToAccountOffer.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
