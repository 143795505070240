import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { chatService } from '../services/chatService'

export const getUserContacts = createAsyncThunk(
  'chat/getAllContacts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await chatService.getUserContacts()
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const fetchUserMessages = createAsyncThunk(
  'chat/getMessages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await chatService.fetchUserMessages(data)
      return response
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const sendMessage = createAsyncThunk(
  'chat/send-message',
  async (data, { rejectWithValue }) => {
    try {
      const response = await chatService.sendMessage(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const searchMessage = createAsyncThunk(
  'chat/search-message',
  async (data, { rejectWithValue }) => {
    try {
      const response = await chatService.searchMessage(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteMessage = createAsyncThunk(
  'chat/delete-message',
  async (data, { rejectWithValue }) => {
    try {
      const response = await chatService.deleteMessage(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

export const searchUsersToChatWith = createAsyncThunk(
  'chat/search-users',
  async (data, { rejectWithValue }) => {
    try {
      const response = await chatService.searchUsersToChatWith(data)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  },
)

const initialState = {
  data: [],
  singleData: {},
  loading: false,
  error: false,
  message: '',
}

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    checkAll: (state) => {
      state.isChecked = !state.isChecked
    },
    resetSingleData: (state) => {
      state.singleData = {}
    },
  },
  extraReducers: {
    [getUserContacts.pending]: (state) => {
      if (state.data.length <= 0) {
        state.loading = true
      }
    },
    [getUserContacts.fulfilled]: (state, action) => {
      state.error = false
      state.data = action.payload
      state.loading = false
    },
    [getUserContacts.rejected]: (state, action) => {
      state.error = true
      state.message = action.payload
      state.loading = false
    },

    [fetchUserMessages.pending]: (state) => {
      state.loading = true
    },
    [fetchUserMessages.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
      state.singleData = payload
    },
    [fetchUserMessages.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [sendMessage.pending]: (state) => {
      state.loading = true
    },
    [sendMessage.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [sendMessage.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [searchMessage.pending]: (state) => {
      state.loading = true
    },
    [searchMessage.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [searchMessage.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [searchUsersToChatWith.pending]: (state) => {
      state.loading = true
    },
    [searchUsersToChatWith.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [searchUsersToChatWith.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },

    [deleteMessage.pending]: (state) => {
      state.loading = true
    },
    [deleteMessage.fulfilled]: (state, { payload }) => {
      state.message = payload?.message
      state.loading = false
    },
    [deleteMessage.rejected]: (state, { payload }) => {
      state.error = true
      state.message = payload
      state.loading = false
    },
  },
})

export const { resetSingleData } = slice.actions
export default slice.reducer
