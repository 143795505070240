import AuthAPI from './authInstance'

const getAllCountries = async (data) => {
  const response = await AuthAPI.get(`/location/countries`)
  return response.data
}

const getStatesByCountry = async (countryid) => {
  const response = await AuthAPI.get(`/location/states/${countryid}`)
  return response.data
}

const getCitiesByState = async (stateid) => {
  const response = await AuthAPI.get(`/location/cities/${stateid}`)
  return response.data
}

const getLGAsByStateId = async (stateid) => {
  const response = await AuthAPI.get(`/location/lgas/${stateid}`)
  return response.data
}

const getCitiesByLGAId = async (lga_id) => {
  const response = await AuthAPI.get(`/location/get-cities-by-lga/${lga_id}`)
  return response.data
}

const getLGA = async (lga_id) => {
  const response = await AuthAPI.get(`/location/show/${lga_id}`)
  return response.data
}

const importLGAandCitiesExcelFile = async (data) => {
  const response = await AuthAPI.post(`/admin/mile-12-market/import-locations`, data)
  return response.data
}

export const LocationService = {
  getAllCountries,
  getStatesByCountry,
  getCitiesByState,
  getLGAsByStateId,
  getCitiesByLGAId,
  getLGA,
  importLGAandCitiesExcelFile,
}
